

























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetNingboChart } from "@/request/storehouse";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private ifShowQushi: any;
  @Watch("ifShowQushi", { immediate: true })
  private ifShowQushiChange() {
    this.ifShow = this.ifShowQushi;
    this.qushiFilter = {};
    this.getData();
  }
  @Prop()
  private targetData: any;
  private ifShow: any = false;
  private qushiFilter: any = {};
  private chartLine: any = {};
  private qushiData: any = {};
  private data: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private qushiTimeChange(item: any) {
    this.qushiFilter = item;
    this.getData();
  }
  private getData() {
    // const res: any = {
    //   data: [
    //     {
    //       create_time: "2023-10-09 11:21:41",
    //       inspect_result: "过高",
    //       inspect_time: "2023-10-09 11:21:41",
    //       origin: "居家检测",
    //       update_time: "2023-10-09 11:21:41",
    //       单位: "mmHg",
    //       参考区间: "60~89",
    //       结果: "200.0",
    //       项目名称: "收缩压",
    //       value: 23,
    //     },
    //     {
    //       create_time: "2023-11-13 17:26:27",
    //       inspect_result: "过低",
    //       inspect_time: "2023-11-13 17:26:27",
    //       origin: "居家检测",
    //       update_time: "2023-11-13 17:26:27",
    //       单位: "mmHg",
    //       参考区间: "60~89",
    //       结果: "50.0",
    //       项目名称: "收缩压",
    //       value: 54,
    //     },
    //     {
    //       create_time: "2023-11-13 17:25:51",
    //       inspect_result: "过高",
    //       inspect_time: "2023-11-13 17:25:51",
    //       origin: "居家检测",
    //       update_time: "2023-11-13 17:25:51",
    //       单位: "mmHg",
    //       参考区间: "60~89",
    //       结果: "180.0",
    //       项目名称: "收缩压",
    //       value: 100,
    //     },
    //     {
    //       create_time: "2023-11-14 09:35:53",
    //       inspect_result: "过高",
    //       inspect_time: "2023-11-14 09:35:53",
    //       origin: "居家检测",
    //       update_time: "2023-11-14 09:35:53",
    //       单位: "mmHg",
    //       参考区间: "60~89",
    //       结果: "180.0",
    //       项目名称: "收缩压",
    //       value: 19,
    //     },
    //     {
    //       create_time: "2023-11-19 09:33:02",
    //       inspect_result: "过高",
    //       inspect_time: "2023-11-19 09:33:02",
    //       origin: "居家检测",
    //       update_time: "2023-11-19 09:33:02",
    //       单位: "mmHg",
    //       参考区间: "60~89",
    //       结果: "220.0",
    //       项目名称: "收缩压",
    //       value: 66,
    //     },
    //   ],
    //   name: [
    //     "2023-10-09 11:21:41",
    //     "2023-11-13 17:26:27",
    //     "2023-11-13 17:25:51",
    //     "2023-11-14 09:35:53",
    //     "2023-11-19 09:33:02",
    //   ],
    // };
    // this.data = res;
    // this.chartLine = {
    //   grid: {
    //     top: 10,
    //     bottom: 40,
    //     left: 80,
    //     right: 80,
    //   },
    //   tooltip: {
    //     trigger: "axis",
    //     axisPointer: {
    //       type: "cross",
    //     },
    //   },
    //   xAxis: {
    //     type: "category",
    //     boundaryGap: false,
    //     data: res.name,
    //     // axisLabel: {
    //     //   formatter: (val: any) => {
    //     //     const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
    //     //     return text;
    //     //   },
    //     // },
    //   },
    //   yAxis: {
    //     type: "value",
    //     axisPointer: {
    //       snap: true,
    //     },
    //   },
    //   series: [
    //     {
    //       name: this.targetData["指标名称"],
    //       type: "line",
    //       smooth: true,
    //       // prettier-ignore
    //       data: res.data,
    //       itemStyle: {
    //         color: "#3387FF",
    //       },
    //     },
    //   ],
    // };
    const params: any = {
      patient_id: this.patient.patient_id,
      指标名称: this.targetData["指标名称"],
      指标代码: this.targetData["指标代码"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetNingboChart(this, params, loading)
      .then((res: any) => {
        loading.close();
        res.data.forEach((ele: any) => {
          ele.value = ele["结果"];
          ele.name = ele.inspect_time;
        });
        this.data = res;
        this.chartLine = {
          grid: {
            top: 10,
            bottom: 40,
            left: 80,
            right: 80,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.name,
            // axisLabel: {
            //   formatter: (val: any) => {
            //     const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
            //     return text;
            //   },
            // },
          },
          yAxis: {
            type: "value",
            axisPointer: {
              snap: true,
            },
          },
          series: [
            {
              name: this.targetData.name,
              type: "line",
              smooth: true,
              // prettier-ignore
              data: res.data,
              itemStyle: {
                color: "#3387FF",
              },
            },
          ],
        };
      })
      .catch(() => {
        loading.close();
      });
  }
  private close() {
    this.$emit("close");
  }
  private mounted() {
    this.getData();
  }
}
