




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Qushi from "./qushi.vue";
@Component({
  components: {
    Qushi,
  },
})
export default class LisDetail extends Vue {
  @Prop()
  private message: any;
  @Prop()
  private link: any;
  @Prop()
  private isShowTool: any;
  private data: any = {
    检验内容: [],
  };
  private keys: any = [
    {
      label: "报告日期",
      name: "报告日期",
    },
    {
      label: "科室名称",
      name: "科室",
    },
    {
      label: "住院号",
      name: "住院号",
    },
    {
      label: "门诊号",
      name: "门诊号",
    },
  ];
  private header: any = ["指标名称", "检验结果", "高低类型", "单位", "参考值"];

  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg) {
      this.data = msg;
    }
  }
  private ifShowQushi: any = false;
  private qushiData: any = {};
  private closeQushi() {
    this.ifShowQushi = false;
  }
  private openQushi(item: any) {
    this.qushiData = item;
    this.ifShowQushi = true;
  }
  private returnList() {
    this.$emit("returnList");
  }
  private importZhibiao(item: any, i: any) {
    // 固定单位数字，单选
    let data: any = this.message["量表"][i];
    // const data: any = {};
    // data.type = "固定单位数字"; // ???需要根据实际情况改
    // data.title = item[0];
    // data.value = item[1];
    // data.unit = item[3];
    data.canDel = true;
    // data.inline = true;
    // data.size = "mini";
    // data.audit_process = [];
    // data.status = "待检查";
    // data.required = false;
    this.$emit("updateContent", data);
  }
}
