
































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetListData, GetDetailData } from "@/request/differences";
import YingxiangList from "./yingxiang/list.vue";
import YingxiangDetail from "./yingxiang/detail.vue";
@Component({
  components: {
    YingxiangList,
    YingxiangDetail,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  @Watch("IfShow", { immediate: true })
  private IfShowChange() {
    this.xitongVisible = this.IfShow;
  }
  private xitongVisible: any = false;
  private ifShowData: any = false;
  private xitongList: any = {};
  private xitongDetail: any = {};
  private filters: any = {
    showXitongshuju: false,
    currentXitong: "中医影像",
    showList: false,
    isShow: true,
  };

  private get center() {
    return this.$store.state.center;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private close() {
    this.$emit("changeIfShow", false);
    this.ifShowData = true;
  }
  private open() {
    this.xitongVisible = true;
  }
  /**
   * @description 搜索
   */
  private searchList(e: any) {
    this.importData(e);
  }
  /**
   * @description 跳转详情
   */
  private jumpDetail(e: any) {
    const params = {
      type: e["报告名称"],
      id: e.id,
    };

    GetDetailData(this, params).then((res) => {
      this.filters.showList = false;
      this.xitongDetail = res;
    });
  }
  /**
   * @description 返回列表页
   */
  private returnList() {
    this.filters.showList = true;
  }
  /**
   * @description 导入医院业务系统数据
   */
  private importData(item: any) {
    const params = {
      patient_id: this.patient.patient_id,
      page_num: item.pageSize || 5,
      current_page: item.currentPage || 1,
      start_time: item.startTime || "",
      end_time: item.endTime || "",
      type: this.filters.currentXitong,
      ifZhuanbing: true,
    };
    GetListData(this, params).then((res: any) => {
      this.ifShowData = true;
      this.xitongList = {
        total_count: res.total_count,
        total_page: res.total_page,
        report: res.data,
      };
      this.xitongVisible = false;
      this.filters.showXitongshuju = true;
      this.filters.showList = true;
      this.filters.isShow = true;
    });
  }
  /**
   * @description 导入影像数据到诊次
   */
  private importSizhen(e: any) {
    this.$emit("importData", e);
  }
  private mounted() {
    this.ifShowData = false;
  }
}
