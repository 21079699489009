

























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class PacsDetail extends Vue {
  @Prop()
  private message: any;
  @Prop()
  private link: any;
  private data: any = {};
  private key: any = [
    {
      label: "申请时间",
      name: "申请时间",
    },
    {
      label: "报告日期",
      name: "报告日期",
    },
    {
      label: "住院号",
      name: "住院号",
    },
    {
      label: "门诊号",
      name: "门诊号",
    },
    {
      label: "检查科室",
      name: "科室",
    },
  ];
  private key2: any = ["描述", "诊断"];
  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg) {
      this.data = msg;
    }
  }
  private returnList() {
    this.$emit("returnList");
  }
  private previewImg(src: any) {
    window.open(src);
  }
}
