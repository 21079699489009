




























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  getXitongList,
  getXitongDetail,
  GetNingboDuijie,
} from "@/request/storehouse";
import MenzhenList from "./ningbo/menzhen/list.vue";
import MenzhenDetail from "./ningbo/menzhen/detail.vue";
import HisList from "./ningbo/his/list.vue";
import HisDetail from "./ningbo/his/detail.vue";
import LisList from "./ningbo/lis/list.vue";
import LisDetail from "./ningbo/lis/detail.vue";
import PacsList from "./ningbo/pacs/list.vue";
import PacsDetail from "./ningbo/pacs/detail.vue";
@Component({
  components: {
    MenzhenList,
    MenzhenDetail,
    HisList,
    HisDetail,
    LisList,
    LisDetail,
    PacsList,
    PacsDetail,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  @Watch("IfShow", { immediate: true })
  private IfShowChange() {
    this.xitongVisible = this.IfShow;
  }
  private xitongVisible: any = false;
  private ifShowData: any = false;
  private xitongList: any = {};
  private xitongDetail: any = {};
  private filters: any = {
    showXitongshuju: false,
    selectedXitong: "门诊系统",
    currentXitong: "",
    showList: false,
    isShow: true,
  };

  private get center() {
    return this.$store.state.center;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get ifZhiyi() {
    return this.$store.state.ifZhiyi;
  }
  private get cZhenci() {
    return this.$store.state.cZhenci;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get cTemplate() {
    return this.$store.state.cTemplate;
  }
  private changePart(val: any) {
    this.filters.selectedXitong = val;
    this.ifShowData = true;
  }
  private updateNingboMenzhen(e: any) {
    this.$emit("updateNingboMenzhen", e);
  }
  private refreshData() {
    this.$emit("refreshData");
  }
  private close() {
    this.$emit("changeIfShow", false);
  }
  /**
   * @description 判断当前用户在当前机构下能否显示某个功能
   */
  private canThisRoleAndStateUse(tool: any) {
    if (!this.user["role"]) {
      return false;
    }
    let canUse = false;
    const roleControl = this.$store.state.roleControl;
    const role = this.user["role"]["名称"];
    const status = this.cZhenci.status;
    const type = this.cZhenci.type;
    try {
      switch (tool) {
        case "添加诊次":
          // 身份有权限添加诊次
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 只能给自己创建的患者添加诊次
            if (this.user.user_id === this.patient.user_id) {
              canUse = true;
            }
          }
          break;
        case "编辑":
          // 身份有权限编辑
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 不能编辑电话访视，只能编辑诊次
            if (type === "诊次") {
              // 诊次状态为草稿可以编辑，或者诊次状态为没有经过打回的待审核状态，经过质量审核员操作后就不能操作了
              const hasShenhe = false;
              // 只能编辑自己创建患者的诊次
              if (this.user.user_id === this.patient.user_id) {
                if (status === "草稿") {
                  canUse = true;
                }
                if (status === "待审核") {
                  if (
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 1 &&
                    this.cZhenci.audit_process[
                      this.cZhenci.audit_process.length - 2
                    ]["status"] === "质量审核员已打回"
                  ) {
                    canUse = false;
                  } else {
                    canUse = true;
                  }
                }
              }
            }
          }
          break;
        case "删除":
          // 身份有权限删除
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 诊次状态为草稿、质量审核员已打回的才可以删除
            if (status === "草稿" || status === "质量审核员已打回") {
              // 只能删除自己创建患者的诊次
              if (this.user.user_id === this.patient.user_id) {
                canUse = true;
              }
            }
          }
          break;
        case "确认无效":
          // 身份有权限确认无效
          if (roleControl["确认无效"].indexOf(role) !== -1) {
            // 只有电话访视能确认无效
            if (type === "电话访视") {
              // 只能确认无效自己创建患者的诊次和电话访视
              if (this.user.user_id === this.patient.user_id) {
                // 诊次状态为待确认的才可以确认无效
                if (status === "待确认" || status === "质量审核员已打回") {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "质疑":
          // 身份有权限质疑
          if (roleControl["质疑"].indexOf(role) !== -1) {
            // 当前是非质疑状态
            if (!this.ifZhiyi) {
              if (role === "质量审核员") {
                // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                if (
                  status === "待审核" ||
                  status === "监查员已打回" ||
                  (status === "质量审核员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              } else if (role === "监查员") {
                // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                if (
                  status === "分中心负责人通过" ||
                  (status === "监查员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "提交":
          // 身份有权限提交
          if (roleControl["提交"].indexOf(role) !== -1) {
            if (role === "研究者" || role === "主要研究者") {
              // 只能提交自己创建患者的诊次和电话访视
              if (this.user.user_id === this.patient.user_id) {
                // 诊次状态为草稿、质量审核员已打回或者待确认
                if (
                  status === "草稿" ||
                  status === "待确认" ||
                  status === "质量审核员已打回"
                ) {
                  canUse = true;
                }
              }
            } else if (role === "质量审核员" || role === "监查员") {
              if (this.ifZhiyi) {
                if (role === "质量审核员") {
                  // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                  if (
                    status === "待审核" ||
                    status === "监查员已打回" ||
                    (status === "质量审核员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                } else if (role === "监查员") {
                  // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                  if (
                    status === "分中心负责人通过" ||
                    (status === "监查员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                }
              }
            }
          }
          break;
        case "审核通过":
          if (roleControl["审核通过"].indexOf(role) !== -1) {
            if (role === "质量审核员") {
              if (status === "待审核" || status === "监查员已打回") {
                canUse = true;
              }
            } else if (role === "监查员") {
              if (status === "分中心负责人通过") {
                canUse = true;
              }
            } else if (role === "分中心负责人") {
              if (status === "质量审核员通过") {
                canUse = true;
              }
            } else if (role === "课题负责人") {
              if (status === "监查员通过") {
                canUse = true;
              }
            }
          }
          break;
        case "复活":
          // 身份有权限复活诊次
          if (roleControl["复活"].indexOf(role) !== -1) {
            // 只能给失活状态的数据进行复活
            if (status === "失活") {
              canUse = true;
            }
          }
          break;
        case "查看监查痕迹":
          if (roleControl["监查痕迹"].indexOf(role) !== -1) {
            canUse = true;
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }
    return canUse;
  }
  private open() {
    this.xitongVisible = true;
  }
  /**
   * @description 搜索
   */
  private searchList(e: any) {
    this.importData(e);
  }
  /**
   * @description 跳转详情
   */
  private jumpDetail(e: any) {
    this.filters.showList = false;
    this.xitongDetail = e;
    return;
    if (this.filters.currentXitong === "门诊系统") {
      this.xitongDetail = {
        门诊信息: [
          {
            key: "西医诊断",
            val: "肾病综合征",
          },
          {
            key: "中医疾病",
            val: "水肿",
          },
          {
            key: "治疗计划",
            val: "水停肾V方，地桂安肾。",
          },
        ],
      };
    } else if (this.filters.currentXitong === "住院系统") {
      this.xitongDetail = {
        住院信息: [
          {
            key: "主诉",
            val: "反复双下肢水肿11年，头昏1月。",
          },
          {
            key: "病情描述",
            val: "水患者11年前无诱因出现双下肢轻度凹陷性水肿，伴腰酸软，疲倦乏力，无尿频尿急尿痛及肉眼血尿，腰不疼痛，小便常规查见蛋白及红细胞（具体不详），到省人民医院作肾穿刺，给予保肾康、安博维等治疗。此后，患者双下肢水肿时有发作，蛋白尿及镜下血尿持续存在，蛋白基本维持在（+）左右，多次到我院就诊，伴腰酸软，疲倦乏力，无尿频尿急尿痛及肉眼血尿，腰不疼痛，小便常规查见蛋白及红细胞（具体不详），到省人民医院作肾穿刺，给予保肾康、安博维等治疗。此后，患者双下肢水肿时有发作，蛋白尿及镜下血尿持续存在，蛋白基本维持在（+）左右，多次到我院就诊，伴腰酸软，疲倦乏力，无尿频尿急尿痛及肉眼血尿，腰不疼痛，小便常规查见蛋白及红细胞（具体不详），到省人民医院作肾穿刺，给予保肾康、安博维等治疗。此后，患者双下肢水肿时有发作。肿",
          },
          {
            key: "四诊摘要",
            val: "患者11年前无诱因出现双下肢轻度凹陷性水肿，伴腰酸软，疲倦乏力，无尿频尿急尿痛及肉眼血尿，腰不疼痛，小便常规查见蛋白及红细胞（具体不详），到省人民医院作肾穿刺，给予保肾康、安博维等治疗。",
          },
        ],
      };
    } else if (this.filters.currentXitong === "LIS系统") {
      this.xitongDetail = {
        title: "血常规",
        content: [
          {
            key: "送检日期",
            val: "2021/1/13  5:33:21",
          },
          {
            key: "报告日期",
            val: "2021/1/13  5:33:21",
          },
          {
            key: "住院号",
            val: "0800883463",
          },
          {
            key: "门诊号",
            val: "0800883463",
          },
          {
            key: "类型",
            val: "血清",
          },
          {
            key: "科室名称",
            val: "骨伤科",
          },
        ],
        table: {
          headers: ["指标名称", "检验结果", "高低类型", "单位", "参考值"],
          data: [
            ["白细胞数目(WBC）", "52.27", "偏高", "pmol/L", "17.80-173.00"],
            [
              "中性粒细胞绝对数(NEUT#)",
              "52.27",
              "偏高",
              "pmol/L",
              "17.80-173.00",
            ],
          ],
        },
      };
    } else if (this.filters.currentXitong === "PACS系统") {
      this.xitongDetail = {
        title: "单次多层CT扫描:颅脑(平扫)",
        content: [
          {
            key: "申请时间",
            val: "2021/1/13  5:33:21",
          },
          {
            key: "报告日期",
            val: "2021/1/13  5:33:21",
          },
          {
            key: "住院号",
            val: "0800883463",
          },
          {
            key: "门诊号",
            val: "0800883463",
          },
          {
            key: "检查科室",
            val: "骨伤科",
          },
        ],
        table: [
          {
            key: "描述",
            val: "定位片示腰椎生理曲度存在，序列连续。部分椎体缘唇样增生、变尖,L2椎体轻度楔形改变。L3/4、L4/5、L5/S1椎间盘不同程度膨出，其后硬膜囊及侧隐窝受压，相应椎管狭窄。椎旁软组织未见异常。",
          },
          {
            key: "诊断",
            val: "1.L3/4、L4/5、L5/S1椎间盘不同程度膨出，相应椎管狭窄。",
          },
        ],
      };
    }
    return;
    const params = {
      report_id: "",
      patient_id: this.patient.patient_id,
    };
    let url = "";
    if (this.filters.currentXitong === "门诊系统") {
      url = "api/project/sg/his";
      params.report_id = e["门诊号"];
    } else if (this.filters.currentXitong === "住院系统") {
      url = "api/project/sg/his";
      params.report_id = e["住院号"];
    } else if (this.filters.currentXitong === "LIS系统") {
      url = "api/project/sghis/details";
      params.report_id = e["报告id"];
    } else if (this.filters.currentXitong === "PACS系统") {
      url = "api/project/sgpacs/details";
      params.report_id = e["报告id"];
    }
    getXitongDetail(this, params, url).then((res) => {
      this.filters.showList = false;
      this.xitongDetail = res;
    });
  }
  private updateContent(e: any) {
    this.$emit("importData", e);
  }
  /**
   * @description 返回列表页
   */
  private returnList() {
    this.filters.showList = true;
  }
  /**
   * @description 导入医院业务系统数据
   */
  private importData(item: any) {
    this.filters.currentXitong = this.filters.selectedXitong;
    const params = {
      page: item.currentPage || 1,
      page_num: 5,
      report_name: item.searchText || "",
      start_time: item.startTime || "",
      end_time: item.endTime || "",
      patient_id: this.patient.patient_id,
      type: this.filters.currentXitong,
      ifZhuanbing: true,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetNingboDuijie(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.xitongVisible = false;
        this.filters.showXitongshuju = true;
        this.filters.showList = true;
        this.filters.isShow = true;
        this.ifShowData = true;
        this.xitongList = {
          total_count: res.total_count,
          total_page: res.total_page,
          report: res.data,
        };
      })
      .catch(() => {
        loading.close();
      });
    return;
    // 测试数据
    this.xitongVisible = false;
    this.filters.showXitongshuju = true;
    this.filters.showList = true;
    this.filters.isShow = true;
    this.ifShowData = true;
    if (this.filters.currentXitong === "门诊系统") {
      this.xitongList = {
        total_count: 1,
        total_page: 1,
        report: [
          {
            就诊日期: "2018.06.13 12:00:32",
            门诊号: "N12990",
            科室名称: "肾病科",
          },
        ],
      };
    } else if (this.filters.currentXitong === "住院系统") {
      this.xitongList = {
        total_count: 2,
        total_page: 2,
        report: [
          {
            入院时间: "2018.06.13 12:00:32",
            出院时间: "2018.06.13 12:00:32",
            住院号: "78377",
            住院科室: "内分泌科",
          },
          {
            入院时间: "2018.06.13 12:00:32",
            出院时间: "2018.06.13 12:00:32",
            住院号: "28980",
            住院科室: "放射医学科",
          },
        ],
      };
    } else if (this.filters.currentXitong === "LIS系统") {
      this.xitongList = {
        total_count: 2,
        total_page: 2,
        report: [
          {
            项目名称: "血常规",
            报告日期: "2018.06.13 12:00:32",
            住院号: "78377",
            门诊号: "N12990",
            科室名称: "肾病科",
          },
          {
            项目名称: "肝功能",
            报告日期: "2018.06.13 12:00:32",
            住院号: "78377",
            门诊号: "N12990",
            科室名称: "内分泌科",
          },
        ],
      };
    } else if (this.filters.currentXitong === "PACS系统") {
      this.xitongList = {
        total_count: 2,
        total_page: 2,
        report: [
          {
            项目名称: "数字化摄影DR:胸部(正侧位)",
            报告时间: "2018.06.13 12:00:32",
            住院号: "78377",
            门诊号: "N12990",
            检查科室: "肾病科",
          },
          {
            项目名称: "多层CT扫描:颅脑(平扫)",
            报告时间: "2018.06.13 12:00:32",
            住院号: "78377",
            门诊号: "N12990",
            检查科室: "肾病科",
          },
        ],
      };
    }
    return;
  }
  /**
   * @description 导入影像数据到诊次
   */
  private importSizhen(e: any) {
    this.$emit("importData", e);
  }
  private mounted() {
    this.ifShowData = false;
  }
}
