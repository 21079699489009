










import { Component, Vue, Prop } from "vue-property-decorator";
import XitongduijieSaas from "./xitongduijie-saas.vue";
@Component({
  components: {
    XitongduijieSaas,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  private get configuration() {
    return this.$store.state.configuration;
  }
  private changeIfShow(e: any) {
    this.$emit("changeIfShow", e);
  }
  private saasImportData(e: any) {
    this.$emit("saasImportData", e);
  }
}
