




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class PacsDetail extends Vue {
  @Prop()
  private message: any;
  @Prop()
  private link: any;
  private data: any = {};
  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg) {
      this.data = msg;
    }
  }
  private returnList() {
    this.$emit("returnList");
  }
  private previewImg(src: any) {
    window.open(src);
  }
}
