


























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import Pagination from "@/components/paginaiton.vue";
@Component({
  components: {
    Pagination,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop()
  private message: any;
  private searchText: any = "";
  private list: any = [];
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg.report) {
      this.filters.total_page = msg.total_page;
      this.filters.total_count = msg.total_count;
      this.list = JSON.parse(JSON.stringify(msg.report));
    }
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.endTime) {
          return (
            time.getTime() > new Date(self.filters.endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.startTime) {
          return (
            new Date(self.filters.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  /**
   * @description 查看详情
   */
  private jumpDetail(item: any) {
    this.$emit("jumpDetail", item);
  }
  /**
   * @description 导入
   */
  private importData(item: any) {
    this.$emit("importData", item);
  }
  /**
   * @description 搜索
   */
  searchData() {
    if (this.filters.startTime && this.filters.endTime) {
      const time =
        new Date(this.filters.endTime).getTime() -
        new Date(this.filters.startTime).getTime();
      if (time < 0) {
        this.$message.warning("结束时间必须大于开始时间");
        return;
      } else if (time < 1) {
        this.$message.warning("结束时间与开始时间间隔必须大于1秒");
        return;
      }
    }
    this.getList();
  }
  getList() {
    const data = {
      startTime: this.filters.startTime,
      endTime: this.filters.endTime,
      searchText: this.filters.search,
      currentPage: this.filters.current_page,
      pageSize: this.filters.page_count,
    };
    this.$emit("searchList", data);
  }
  mounted() {
    this.filters.page_count = 5;
  }
}
