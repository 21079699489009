


























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class LisDetail extends Vue {
  @Prop()
  private message: any;
  @Prop()
  private link: any;
  @Prop()
  private isShowTool: any;
  private data: any = {};

  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg && msg.title) {
      this.data = msg;
    }
  }
  private returnList() {
    this.$emit("returnList");
  }
  private importZhibiao(item: any, i: any) {
    // 固定单位数字，固定单位字符串，单选
    const data = this.message.table.components[i];
    data.title = item[0];
    data.value = item[1];
    data.unit = item[3];
    data.audit_process = [];
    data.status = "待检查";
    data.required = false;
    this.$emit("updateContent", data);
  }
}
