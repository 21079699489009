









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TemplateTransfer from "../template-transfer/template-transfer.vue";
import She from "./she/she.vue";
@Component({
  components: {
    TemplateTransfer,
    She,
  },
})
export default class Name extends Vue {
  @Prop()
  private template: any;
  @Prop()
  private zhenci: any;
  @Prop()
  private content: any;

  private cContent: any = {};
  private bitianMsg: any = [];
  @Watch("content", { immediate: true })
  private contentChange(content: any) {
    this.$set(this, "cContent", content);
  }

  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private updateContent(e: any) {
    this.$emit("update:content", e);
  }
}
