

































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private content: any;
  private mianOptions1: any = [
    "红黄隐隐，明润含蓄",
    "面色红",
    "面色白",
    "面色姜黄",
    "面色黄",
  ];
  private mianOptions2: any = ["有", "无"];
  private mianOptions3: any = ["有光泽", "少量光泽", "无光泽"];
  private mianOptions4: any = ["唇色青紫", "唇色暗红", "唇色淡", "唇色红"];
  private get c() {
    const data = JSON.parse(JSON.stringify(this.content));
    return data;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private showPreview(src: any) {
    window.open(src);
  }
  private valueChange(e: any) {
    this.$emit("updateContent", this.c);
  }
}
