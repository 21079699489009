







































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getXitongList, getXitongDetail } from "@/request/storehouse";
import HisList from "./shuguang/his/list.vue";
import HisDetail from "./shuguang/his/detail.vue";
import LisList from "./shuguang/lis/list.vue";
import LisDetail from "./shuguang/lis/detail.vue";
import PacsList from "./shuguang/pacs/list.vue";
import PacsDetail from "./shuguang/pacs/detail.vue";
@Component({
  components: {
    HisList,
    HisDetail,
    LisList,
    LisDetail,
    PacsList,
    PacsDetail,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  @Watch("IfShow", { immediate: true })
  private IfShowChange() {
    this.xitongVisible = this.IfShow;
  }
  private xitongVisible: any = false;
  private ifShowData: any = false;
  private xitongList: any = {};
  private xitongDetail: any = {};
  private filters: any = {
    showXitongshuju: false,
    selectedXitong: "住院系统",
    currentXitong: "",
    showList: false,
    isShow: true,
  };

  private get center() {
    return this.$store.state.center;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get ifZhiyi() {
    return this.$store.state.ifZhiyi;
  }
  private get cZhenci() {
    return this.$store.state.cZhenci;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get cTemplate() {
    return this.$store.state.cTemplate;
  }
  private changePart(val: any) {
    this.filters.selectedXitong = val;
    this.ifShowData = true;
  }
  private close() {
    this.$emit("changeIfShow", false);
  }
  /**
   * @description 判断当前用户在当前机构下能否显示某个功能
   */
  private canThisRoleAndStateUse(tool: any) {
    if (!this.user["role"]) {
      return false;
    }
    let canUse = false;
    const roleControl = this.$store.state.roleControl;
    const role = this.user["role"]["名称"];
    const status = this.cZhenci.status;
    const type = this.cZhenci.type;
    try {
      switch (tool) {
        case "添加诊次":
          // 身份有权限添加诊次
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 只能给自己创建的患者添加诊次
            if (this.user.user_id === this.patient.user_id) {
              canUse = true;
            }
          }
          break;
        case "编辑":
          // 身份有权限编辑
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 不能编辑电话访视，只能编辑诊次
            if (type === "诊次") {
              // 诊次状态为草稿可以编辑，或者诊次状态为没有经过打回的待审核状态，经过质量审核员操作后就不能操作了
              const hasShenhe = false;
              // 只能编辑自己创建患者的诊次
              if (this.user.user_id === this.patient.user_id) {
                if (status === "草稿") {
                  canUse = true;
                }
                if (status === "待审核") {
                  if (
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 1 &&
                    this.cZhenci.audit_process[
                      this.cZhenci.audit_process.length - 2
                    ]["status"] === "质量审核员已打回"
                  ) {
                    canUse = false;
                  } else {
                    canUse = true;
                  }
                }
              }
            }
          }
          break;
        case "删除":
          // 身份有权限删除
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 诊次状态为草稿、质量审核员已打回的才可以删除
            if (status === "草稿" || status === "质量审核员已打回") {
              // 只能删除自己创建患者的诊次
              if (this.user.user_id === this.patient.user_id) {
                canUse = true;
              }
            }
          }
          break;
        case "确认无效":
          // 身份有权限确认无效
          if (roleControl["确认无效"].indexOf(role) !== -1) {
            // 只有电话访视能确认无效
            if (type === "电话访视") {
              // 只能确认无效自己创建患者的诊次和电话访视
              if (this.user.user_id === this.patient.user_id) {
                // 诊次状态为待确认的才可以确认无效
                if (status === "待确认" || status === "质量审核员已打回") {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "质疑":
          // 身份有权限质疑
          if (roleControl["质疑"].indexOf(role) !== -1) {
            // 当前是非质疑状态
            if (!this.ifZhiyi) {
              if (role === "质量审核员") {
                // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                if (
                  status === "待审核" ||
                  status === "监查员已打回" ||
                  (status === "质量审核员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              } else if (role === "监查员") {
                // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                if (
                  status === "分中心负责人通过" ||
                  (status === "监查员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "提交":
          // 身份有权限提交
          if (roleControl["提交"].indexOf(role) !== -1) {
            if (role === "研究者" || role === "主要研究者") {
              // 只能提交自己创建患者的诊次和电话访视
              if (this.user.user_id === this.patient.user_id) {
                // 诊次状态为草稿、质量审核员已打回或者待确认
                if (
                  status === "草稿" ||
                  status === "待确认" ||
                  status === "质量审核员已打回"
                ) {
                  canUse = true;
                }
              }
            } else if (role === "质量审核员" || role === "监查员") {
              if (this.ifZhiyi) {
                if (role === "质量审核员") {
                  // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                  if (
                    status === "待审核" ||
                    status === "监查员已打回" ||
                    (status === "质量审核员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                } else if (role === "监查员") {
                  // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                  if (
                    status === "分中心负责人通过" ||
                    (status === "监查员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                }
              }
            }
          }
          break;
        case "审核通过":
          if (roleControl["审核通过"].indexOf(role) !== -1) {
            if (role === "质量审核员") {
              if (status === "待审核" || status === "监查员已打回") {
                canUse = true;
              }
            } else if (role === "监查员") {
              if (status === "分中心负责人通过") {
                canUse = true;
              }
            } else if (role === "分中心负责人") {
              if (status === "质量审核员通过") {
                canUse = true;
              }
            } else if (role === "课题负责人") {
              if (status === "监查员通过") {
                canUse = true;
              }
            }
          }
          break;
        case "复活":
          // 身份有权限复活诊次
          if (roleControl["复活"].indexOf(role) !== -1) {
            // 只能给失活状态的数据进行复活
            if (status === "失活") {
              canUse = true;
            }
          }
          break;
        case "查看监查痕迹":
          if (roleControl["监查痕迹"].indexOf(role) !== -1) {
            canUse = true;
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }
    return canUse;
  }
  private open() {
    this.xitongVisible = true;
  }
  /**
   * @description 搜索
   */
  private searchList(e: any) {
    this.importData(e);
  }
  /**
   * @description 跳转详情
   */
  private jumpDetail(e: any) {
    const params = {
      // report_id: e["报告id"],
      // report_id: "8392894",
      report_id: "",
      patient_id: this.patient.patient_id,
    };
    let url = "";
    if (this.filters.currentXitong === "住院系统") {
      url = "api/project/sg/his";
      params.report_id = e["住院号"];
    } else if (this.filters.currentXitong === "LIS系统") {
      url = "api/project/sghis/details";
      params.report_id = e["报告id"];
    } else if (this.filters.currentXitong === "PACS系统") {
      url = "api/project/sgpacs/details";
      params.report_id = e["报告id"];
    }
    getXitongDetail(this, params, url).then((res) => {
      this.filters.showList = false;
      this.xitongDetail = res;
    });
  }
  private updateContent(e: any) {
    this.$emit("importData", e);
  }
  /**
   * @description 返回列表页
   */
  private returnList() {
    this.filters.showList = true;
  }
  /**
   * @description 导入医院业务系统数据
   */
  private importData(item: any) {
    this.filters.currentXitong = this.filters.selectedXitong;
    const params = {
      page: item.currentPage || 1,
      page_num: 5,
      report_name: item.searchText || "",
      start_date: item.startTime || "",
      end_date: item.endTime || "",
      patient_id: this.patient.patient_id,
    };
    let url = "";
    if (this.filters.currentXitong === "住院系统") {
      url = "api/project/sg/list";
    } else if (this.filters.currentXitong === "LIS系统") {
      url = "api/project/sghis/list";
    } else if (this.filters.currentXitong === "PACS系统") {
      url = "api/project/sgpacs/list";
    }
    getXitongList(this, params, url).then((res: any) => {
      this.xitongList = {
        total_count: res.total_count,
        total_page: res.total_page,
        report: res.data,
      };
      this.xitongVisible = false;
      this.filters.showXitongshuju = true;
      this.filters.showList = true;
      this.filters.isShow = true;
      this.ifShowData = true;
    });
  }
  /**
   * @description 导入影像数据到诊次
   */
  private importSizhen(e: any) {
    this.$emit("importData", e);
  }
  private mounted() {
    this.ifShowData = false;
  }
}
