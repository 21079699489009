












































import { Component, Vue, Prop } from "vue-property-decorator";
import XingzhuangYanse from "./xingzhuang-yanse.vue";
import Yanse from "./yanse.vue";
import Wenli from "./wenli.vue";
@Component({
  components: {
    XingzhuangYanse,
    Yanse,
    Wenli,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  private cState: any = "形状颜色";
  private get c() {
    const data = JSON.parse(JSON.stringify(this.content));
    return data;
  }
  private showPreview(src: any) {
    window.open(src);
  }
  /**
   * @description 改变显示部分
   */
  private changeState(state: any) {
    this.cState = state;
  }
}
