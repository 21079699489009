















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class HisDetail extends Vue {
  @Prop()
  private message: any;
  @Prop()
  private link: any;
  private data: any = {};
  private keys: any = ["西医诊断", "中医疾病", "治疗计划"];

  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    if (msg) {
      this.data = msg;
    }
  }
  private returnList() {
    this.$emit("returnList");
  }
}
