


















import { Component, Vue, Prop } from "vue-property-decorator";
import XitongduijieShuguang from "./xitongduijie-shuguang.vue";
import XitongduijieNingbo from "./xitongduijie-ningbo.vue";
@Component({
  components: {
    XitongduijieShuguang,
    XitongduijieNingbo,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  private get configuration() {
    return this.$store.state.configuration;
  }
  private changeIfShow(e: any) {
    this.$emit("changeIfShow", e);
  }
  private shuguangImportData(e: any) {
    this.$emit("shuguangImportData", e);
  }
  private ningboImportData(e: any) {
    this.$emit("ningboImportData", e);
  }
  private updateNingboMenzhen(e: any) {
    this.$emit("updateNingboMenzhen", e);
  }
  private refreshData() {
    this.$emit("refreshData");
  }
}
