













































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/paginaiton.vue";
@Component({
  components: {
    Pagination,
  },
})
export default class LisList extends Vue {
  @Prop()
  private message: any;
  private startTime: any = "";
  private endTime: any = "";
  private searchText: any = "";
  private list: any = [];
  private total: any = 0;
  private currentPage: any = 1;
  private pageSize: any = 5;
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();

  @Watch("message", { immediate: true })
  private messageChange(msg: any) {
    this.total = msg.total_count;
    if (msg.report) {
      this.list = JSON.parse(JSON.stringify(msg.report));
    } else {
      this.list = [];
    }
  }
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.endTime) {
          return (
            time.getTime() > new Date(self.endTime).getTime() ||
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2019-01-01").getTime()
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2019-01-01").getTime()
          );
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.startTime) {
          return (
            new Date(self.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2019-01-01").getTime()
          );
        }
      },
    };
  }
  /**
   * @description 查看详情
   */
  private jumpDetail(item: any) {
    this.$emit("jumpDetail", item);
  }
  /**
   * @description 切换页码
   */
  private handleCurrentPageChange(val: any) {
    this.currentPage = val;
    this.search();
  }
  /**
   * @description 处理翻页器的每页条数变化
   */
  private handleCurrentSizeChange(val: any) {
    this.currentPage = 1;
    this.pageSize = val;
    this.search();
  }
  /**
   * @description 搜索
   */
  private search() {
    if (this.startTime && this.endTime) {
      const time =
        new Date(this.endTime).getTime() - new Date(this.startTime).getTime();
      if (time < 0) {
        this.$message.warning("结束时间必须大于开始时间");
        return;
      } else if (time < 1) {
        this.$message.warning("结束时间与开始时间间隔必须大于1秒");
        return;
      }
    }
    const data = {
      startTime: this.startTime,
      endTime: this.endTime,
      searchText: this.searchText,
      currentPage: this.currentPage,
    };
    this.$emit("searchList", data);
  }
}
